<template>
  <div class="">
    <div style="width:1128px;float:left;padding:20px 0px 0px 30px ;height:700px;background-color: #fefefe;">

      <img style="width:1067px;height:319px;margin-top:20px;float:left" src="../assets/rili.png">  
      <p style="padding-left:50px;float:left;font-weight:600;color:#000;font-size:20px;width:100%">操作区</p>  
      <div style="display:flex;width:1067px;">
        <img  @click="goToPage('edit')" style="flex:1;width:30%;height:290px;" src="../assets/new1.png">
        <img @click="goToPage('edit')" style="flex:1;width:30%;height:290px;" src="../assets/new2.png">
        <img @click="goToPage('edit')" style="flex:1;width:30%;height:290px;" src="../assets/new3.png">
      </div>   
    </div>
    <div style="width:470px;float:right;">
    
    <side-bar></side-bar>

    </div>






    <!--<div class="grid grid-cols-1 gap-2 xl:grid-cols-2">
      <div class="rounded box record bg-primary">
        <div class="bg"></div>
        <div
          class="absolute top-0 left-0 flex items-center justify-between px-4 con"
        >
          <div class="flex items-center">
            <img src="../static/index/register.png" alt />
            <span class="pl-4 text-base text-white text-bold">确认码</span>
          </div>
          <div>
            <span
              class="text-base text-black btn text-bold"
              @click="goToPage('confirmation')"
              >开始登记</span
            >
          </div>
        </div>
      </div>
      <div class="rounded box bg-primary">
        <div class="bg bg2"></div>
        <div
          class="absolute top-0 left-0 flex items-center justify-between px-4 con"
        >
          <div class="flex items-center">
            <img src="../static/index/apply.png" alt />
            <span class="pl-4 text-base text-white text-bold">本地下单</span>
          </div>
          <div>
            <span
              class="text-base text-black btn text-bold"
              @click="goToPage('localCollect')"
              >立即申请</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-2 pt-2 xl:grid-cols-2">
      <div class="rounded max_box bg-primary">
        <div class="bg bg2"></div>
        <div
          class="absolute top-0 left-0 flex items-center justify-between px-4 con"
        >
          <div class="flex items-center">
            <img src="../static/index/caiji.png" alt />
            <span class="pl-4 text-base text-white text-bold">采集工作</span>
          </div>
          <div>
            <span
              class="text-base text-black btn text-bold"
              @click="goToPage('collectWork')"
              >开始采集</span
            >
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-2">
        <div class="rounded box bg-primary">
          <div class="bg bg3"></div>
          <div
            class="absolute top-0 left-0 flex items-center justify-between px-4 con"
          >
            <div class="flex items-center">
              <img src="../static/index/robot.png" alt />
              <span class="pl-4 text-base text-white text-bold"
                >机器人管理</span
              >
            </div>
            <div>
              <span
                class="text-base text-black btn text-bold"
                @click="goToPage('robot')"
                >立即管理</span
              >
            </div>
          </div>
        </div>
        <div class="rounded box bg-primary">
          <div class="bg bg4"></div>
          <div
            class="absolute top-0 left-0 flex items-center justify-between px-4 con"
          >
            <div class="flex items-center">
              <img src="../static/index/manage.png" alt />
              <span class="pl-4 text-base text-white text-bold">客户管理</span>
            </div>
            <div>
              <span
                class="text-base text-black btn text-bold"
                @click="goToPage('client')"
                >立即管理</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-2 pt-2 xl:grid-cols-2">
      <div class="rounded box bg-primary">
        <div class="bg bg5"></div>
        <div
          class="absolute top-0 left-0 flex items-center justify-between px-4 con"
        >
          <div class="flex items-center">
            <img src="../static/index/file.png" alt />
            <span class="pl-4 text-base text-white text-bold">上传文件</span>
          </div>
          <div>
            <span
              class="text-base text-black btn text-bold"
              @click="goToPage('uploadFile')"
              >立即查看</span
            >
          </div>
        </div>
      </div>
      <div class="rounded box bg-primary">
        <div class="bg bg6"></div>
        <div
          class="absolute top-0 left-0 flex items-center justify-between px-4 con"
        >
          <div class="flex items-center">
            <img src="../static/index/stop.png" alt />
            <span class="pl-4 text-base text-white text-bold">紧急停止</span>
          </div>
          <div>
            <span
              class="text-base text-black btn text-bold"
              @click="goToPage('stop')"
              >立即停止</span
            >
          </div>
        </div>
      </div>
    </div>

     <div class="flex items-center justify-around pt-6 footer">
      <div
        class="py-2 bg-gray-100 rounded text-primary"
        @click="goToPage('/tags')"
      >
        标签管理
      </div>
      <div
        class="py-2 bg-gray-100 rounded text-primary"
        @click="goToPage('/content')"
      >
        内容管理
      </div>
      <div class="py-2 bg-gray-100 rounded text-primary">系统维护</div>
      <div class="py-2 bg-gray-100 rounded text-primary">查看日志</div>
      <div class="py-2 bg-gray-100 rounded text-primary">备用</div>
      <div class="py-2 bg-gray-100 rounded text-primary" @click="exit">
        退出
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SideBar from "components/sidebar/sidebar";
export default {
  name: "Home",
  data() {
    return {
      nowTime:''
    };
  },
    components: {
   
    SideBar,
   
  },
  created() {
    // const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN")
    //   ? JSON.parse(localStorage.getItem("ACCESS_TOKEN"))
    //   : "";
    // if (!ACCESS_TOKEN) {
    //   this.$router.push("/");
    //   this.$notification["error"]({
    //     message: "错误",
    //     description: "请先登录",
    //     duration: 4,
    //   });
    // }
    this.nowTime = this.getNowDate()
  },
  methods: {
    ...mapActions(["Logout"]),
    // 页面跳转
    goToPage(pagePath) {
      this.$router.push({ path: pagePath });
    },
    getNowDate ()  {
  var date = new Date();
  var sign2 = ":";
  var year = date.getFullYear() // 年
  var month = date.getMonth() + 1; // 月
  var day = date.getDate(); // 日
  var hour = date.getHours(); // 时
  var minutes = date.getMinutes(); // 分
  var seconds = date.getSeconds() //秒
  var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
  var week = weekArr[date.getDay()];
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
},

    exit() {
      this.$confirm({
        title: "确定退出登录吗？",
        content: "",
        onOk: () => {
          this.Logout({})
            .then((res) => {
              this.$notification["success"]({
                message: "成功",
                description: "成功注销账号",
                duration: 4,
              });
              this.$router.push("/");
            })
            .catch((err) => {
              this.$notification["error"]({
                message: "失败",
                description: "账号注销失败",
                duration: 4,
              });
            })
            .finally(() => {});
        },
        onCancel: () => {
          console.log("Cancel");
        },
        class: "test",
      });
    },
  },
};
</script>
<style lang="less" scoped>

.bgbai{
  background:  url(../assets/bgbai.png) no-repeat 100%;
  background-size: 100%;}
.box {
  position: relative;
  height: 160px !important;
  overflow: hidden;

  .bg {
    height: 100%;
    width: 100%;
    background: url("../static/index/register_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center right 0px;
  }
  .bg2 {
    background: url("../static/index/apply_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center right 0px;
  }
  .bg3 {
    background: url("../static/index/robot_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center right 0px;
  }
  .bg4 {
    background: url("../static/index/manage_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center right 0px;
  }
  .bg5 {
    background: url("../static/index/file_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center right 0px;
  }
  .bg6 {
    background: url("../static/index/stop_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center right 0px;
  }
}
.max_box {
  height: 330px;
  position: relative;
  overflow: hidden;
  .bg {
    height: 100%;
    width: 100%;
    background: url("../static/index/caiji_bg.png") no-repeat;
    background-size: auto 100%;
    background-position: center right 0px;
  }
}
.con {
  width: 100%;
  height: 100%;
  img {
    width: 80px;
    height: 80px;
  }
  .btn {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    text-align: center;
    width: 110px;
    box-shadow: 0px 0px 6px 0px #3db859;
    border-radius: 4px;
    background: #dcf9e2;
  }
  .btn {
    cursor: pointer;
  }
}
.footer {
  div {
    width: 100px;
    text-align: center;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
